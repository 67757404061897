import { last } from 'lodash';
import slugify from 'slugify';

import { i18n, Locale } from '@lib/services/i18n';
import { PageProps } from '@lib/services/page';
import { base64ToObject, mergeDeep, objectToBase64 } from '@lib/utils';

import { STATIC_ROUTES } from './config';
import type {
  CmsRouteParamType,
  CmsRouteParams,
  StaticRouteConfig,
  StaticRoutePageId
} from './types';
import { PathParamsOptions } from './types/routes.types';

const getRootPath = (locale: Locale) => {
  return `/${locale}/`;
};

const addTrailingSlash = (path: string) => {
  if (last(path) !== '/') {
    // eslint-disable-next-line no-param-reassign
    path += '/';
  }

  return path;
};

const getLocalizedRootPath = (pageProps: PageProps, trailingSlash?: boolean) => {
  const locale = routes.getLocaleFromPageProps(pageProps);

  return `/${locale}${trailingSlash ? '/' : ''}`;
};

const getPathFromSlugs = (slugs: string[], hasTrailingSlash?: boolean) => {
  let path = `/${slugs.join('/')}`;
  if (hasTrailingSlash) {
    return addTrailingSlash(path);
  }

  return path;
};

const getPathFromPageProps = (pageProps: PageProps, hasTrailingSlash?: boolean) => {
  if (!pageProps) return getRootPath('en');

  const slugs = pageProps.params.slugs || [];
  let path = `/${pageProps.params.lng}/${slugs.join('/')}`;

  if (slugs[0] === '_home') {
    path = `/${pageProps.params.lng}`;
  }

  if (hasTrailingSlash) {
    addTrailingSlash(path);
  }

  return path;
};

const getStaticRouteConfigByPageId = (pageId: StaticRoutePageId) => {
  return STATIC_ROUTES.find(s => s.pageId === pageId) as StaticRouteConfig;
};

const getLocaleFromPageProps = (pageProps: PageProps) => {
  return pageProps.params.lng;
};

const isEqualPath = (path1: string, path2: string) => {
  return addTrailingSlash(path1) === addTrailingSlash(path2);
};

const getSlugsFromPath = (path?: string) => {
  if (!path) return [];

  return path.split('/').filter(s => s !== '');
};

const isEqualRootPath = (path1: string, path2: string) => {
  const path1Slugs = getSlugsFromPath(path1);
  const path2Slugs = getSlugsFromPath(path2);

  return path1Slugs[1] === path2Slugs[1];
};

const getStaticRouteConfigByPageProps = (pageProps: PageProps) => {
  const locale = pageProps.params.lng;
  if (!i18n.getLanguages(locale).includes(locale)) {
    return undefined;
  }
  const path = getPathFromPageProps(pageProps, true);

  return STATIC_ROUTES.find(s => {
    if (isEqualPath(`/${locale}${s.route[locale]?.path}`, path)) {
      return true;
    }

    if (isEqualRootPath(`/${locale}${s.route[locale]?.path}`, path) && s.catchBasePath) {
      return true;
    }

    return false;
  });
};

const getStaticPathByPageId = (
  pageId: StaticRoutePageId,
  locale: Locale,
  hasTrailingSlash?: boolean
) => {
  const config = getStaticRouteConfigByPageId(pageId);

  if (!config) {
    return getRootPath(locale);
  }

  const path = `/${locale}${config.route[locale]?.path}`;

  if (!path) {
    return getRootPath(locale);
  }

  if (hasTrailingSlash) {
    return addTrailingSlash(path);
  }

  return path;
};

const isStaticRoute = (pageProps: PageProps) => {
  const config = getStaticRouteConfigByPageProps(pageProps);

  return config !== undefined;
};

const slugifyUrlParam = (string: string, locale: Locale) => {
  if (!string) return `/${locale}/`;

  return slugify(string, { locale, lower: true, trim: true });
};

const objToRouteParam = (obj: any) => {
  const str = objectToBase64(obj);

  return encodeURIComponent(str);
};

const routeParamToObj = <T = any>(param: string): T => {
  if (!param) return {} as T;

  const obj = base64ToObject(decodeURIComponent(param));

  return obj as T;
};

const getCmsParams = (params: string) => {
  return routeParamToObj<CmsRouteParams>(params);
};

const getServerCmsParamsByBlockId = (blockId: number, pageProps: Record<string, any>) => {
  const cmsParams = pageProps.searchParams?.cms;

  if (cmsParams) {
    return getCmsParams(cmsParams)?.[blockId.toString()];
  }

  return undefined;
};

const getCmsQueryString = (
  blockId: number,
  params?: CmsRouteParamType,
  currentParamsStr?: string | null
) => {
  let routeParams: CmsRouteParams = {};
  if (currentParamsStr) {
    routeParams = routeParamToObj<CmsRouteParams>(currentParamsStr);
  }

  const cmsParams: CmsRouteParams | undefined = params
    ? {
        [blockId.toString()]: params
      }
    : undefined;

  if (cmsParams) {
    routeParams = mergeDeep(routeParams, cmsParams);
  } else {
    delete routeParams[blockId.toString()];
  }

  if (Object.keys(routeParams).length === 0) {
    return undefined;
  }

  return objToRouteParam(routeParams);
};

const getBlogPostUrl = (locale: Locale, blogPostPageUrl?: string, slug?: string) => {
  if (!slug || !blogPostPageUrl) return undefined;

  return `${blogPostPageUrl}/${slug}`;
};

const replacePathParams = (path: string, repl?: PathParamsOptions) => {
  if (!repl) {
    return path;
  }

  let newPath = path;

  Object.keys(repl).forEach(key => {
    newPath = newPath.replace(`:${key}`, repl[key] as string);
  });

  return newPath;
};

const getUrlSearchParams = (query?: Record<string, string | number | boolean>) => {
  const srchParams: string[] = [];

  if (query) {
    Object.keys(query).forEach(key => {
      srchParams.push(`${key}=${query[key]}`);
    });
  }

  let srch = '';
  if (srchParams.length > 0) {
    srch = `?${srchParams.join('&')}`;
  }

  return srch;
};

const routes = {
  addTrailingSlash,
  getPathFromPageProps,
  getPathFromSlugs,
  getStaticRouteConfigByPageId,
  getStaticPathByPageId,
  getStaticRouteConfigByPageProps,
  isStaticRoute,
  getLocaleFromPageProps,
  slugifyUrlParam,
  getLocalizedRootPath,
  isEqualPath,
  isEqualRootPath,
  getSlugsFromPath,
  getCmsParams,
  getCmsQueryString,
  getServerCmsParamsByBlockId,
  getBlogPostUrl,
  replacePathParams,
  getUrlSearchParams
};

export { routes as routes };
