'use client';

import React from 'react';

import { cx } from '@pt-frontends/styled-system/css';
import { AspectRatio } from '@pt-frontends/styled-system/jsx';
import { borderBox } from '@pt-frontends/styled-system/recipes';
import { cmsContentBlogPosts } from '@pt-frontends/styled-system-web/recipes';
import { Button } from '@ui/button';
import { Img } from '@ui/img';
import { TagList } from '@ui/tag';
import { Text } from '@ui/text';
import Link from 'next/link';

import { usePageProps } from '@lib/hooks';
import type { Cms_BlogPost } from '@lib/services/cms';
import { useTranslation } from '@lib/services/i18n/client';
import { routes } from '@lib/services/routes';

interface Props {
  data: Cms_BlogPost;
  blogPostsBaseUrl?: string;
}

const BlogPostItem: React.FC<Props> = ({ data, blogPostsBaseUrl }) => {
  const { item, button, img, itemTitle, itemLink, tagList } = cmsContentBlogPosts();
  const { t } = useTranslation();
  const pageProps = usePageProps();
  const link = routes.getBlogPostUrl(pageProps.params.lng, blogPostsBaseUrl, data.slug?.slug);

  const content = () => {
    return (
      <div className={cx('group', borderBox(), item)}>
        <div className={tagList}>
          <TagList
            items={
              data.tags
                ?.sort((a, b) => a.localeCompare(b))
                .map(tag => ({ key: tag, label: tag })) || []
            }
          />
        </div>
        {data.thumbnail?.data && (
          <AspectRatio ratio={1.6} className={img}>
            <Img fill data={data.thumbnail} />
          </AspectRatio>
        )}
        <Text variant="h5" as="h4" className={itemTitle}>
          {data.title}
        </Text>

        {link && (
          <div className={itemLink}>
            <Button className={button} variant="tertiary" icon="arrow-right">
              {t('uiWeb.cmsContents.blogPost.readMore')}
            </Button>
          </div>
        )}
      </div>
    );
  };

  if (link) {
    return <Link href={link}>{content()}</Link>;
  }

  return content();
};

export default BlogPostItem;
